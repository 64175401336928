@import "~slick-carousel/slick/slick.css"; 
.homePage{
  width: 100%;
  scroll-behavior: smooth;
  overflow: hidden;
  .launched {
    color: #DDD1E1;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1;
  }
  .banner{
    width: 100%;
    height: 150px;
    margin-top: 100px;
    img{
      width: 100%;
      height: 150px;
      object-fit: cover;
      object-position: center;
      position: relative;
      z-index: 10;
    }
  }
  .chains{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .networks{
    display: flex;
    // justify-content:center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    svg{
      height: 20px;
      margin-right: 5px;
    }
    &>div{
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      svg{
        height: 15px;
      }
      gap: 8px;
    }
  }
  .datas{
    color: #fff;
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin-right: 130px;
    @media (max-width: 1200px) {
      margin-right: 0;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      margin-right: 0;
    }
    &>div{
      border: 1px solid #403544;
      border-radius: 18px;
      padding: 15px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, #403544, #403544);
      &>div{
        &:first-of-type{
          color: #DDD1E1;
          font-size: 20px;
          font-weight: 600;
        }
        &:last-of-type{
          font-size: 40px;
          background: -webkit-linear-gradient(#9E68DD,#E594E5,#6BDAB0);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Righteous;
          @media (max-width: 992px) {
            font-size: 30px;
          }
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
    }
  }
  .ant-layout-header{
      position: fixed;
      z-index: 1000;
      width: 100%;
      background: #000;
      height: 100px;
      // height: 128px;
      padding: 0;
      line-height: 1;
      @media (max-width: 767px) {
        height: 64px;
      }
  }
  .header{
      background: #000;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-areas: "logo nav";
      align-items: center;
      .Nav {
          display: grid;
          grid-area: nav;
          grid-template-columns: repeat(6, auto);
          align-items: center;
          justify-items: center;
          margin-right: -18px;
          height: 60px;
          border-radius: 60px;
          background: linear-gradient(180deg, rgba(32, 26, 68, 0.90) 2.08%, rgba(18, 8, 49, 0.90) 20.31%, rgba(11, 10, 43, 0.90) 81.25%, rgba(35, 19, 55, 0.90) 98.44%);
          @media (max-width: 767px) {
            margin-right: 0;
          }
      }
      .Logo {
          float: left;
          width: auto;
          height: 40px;
          grid-area: logo;
      }
        .Nav a {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          transition: 0.5s;
          text-decoration: none;
          padding: 10px 16px;
          &:hover{
            color: #F19DFF;
          }
        }
        
        .Burger {
          display: none;
          grid-area: burger;
          margin: 0;
          padding: 0;
          justify-self: end;
          font-size: 40px;
          border: none;
          background: none;
          outline: none;
          transition: 0.1s;
          font-size: 26px;
        }
        
        @media (max-width: 767px) {
          .Nav {
              grid-template-rows: repeat(6, auto);
              grid-template-columns: none;
              grid-row-gap: 10px;
              padding: 0;
              background: #fff;
              color: #000;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              width: 100%;
              position: absolute;
              top: 64px;
              left: 0;
              padding-bottom: 10px;
          }
          .Burger {
            display: inline;
          }
        }
        
        .NavAnimation-enter {
          opacity: 0;
          transform: scale(0.5);
        }
        .NavAnimation-enter-active {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 350ms, transform 350ms;
        }
        .NavAnimation-exit {
          opacity: 1;
        }
        .NavAnimation-exit-active {
          opacity: 0;
          transform: scale(0.5);
          transition: opacity 350ms, transform 350ms;
        }
        
  }
  @media (max-width: 767px) {
      .header {
          width: 100vw;
        grid-template-areas: "logo burger";
      }
  }
  .ant-layout-content{
    position: relative;
    left: 0;
    top: 0;
    z-index: 100;
    background: #000;
  }
  .ant-btn-primary{
      background: #5542F6;
      color: #fff;
      border-radius: 48px;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      padding: 0 30px;
      a{
        color: #fff!important;
        padding: 0!important;
        line-height: 38px;
        &:hover{
          background: #5542F6!important;
        }
      }
  }
  .ant-btn-default{
      background: #fff;
      color: #5542F6;
      border-radius: 6px;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      a{
        color: #5542F6!important;
        padding: 0!important;
        line-height: 38px;
        &:hover{
          background: #fff!important;
        }
      }
  }
  .site-layout-background {
      background: #fff;
  }
  .compenyList{
    list-style: none;
    padding-left: 0;
    display: grid;
    margin: 10px 0;
    padding-right: 220px;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, auto);
      padding: 0 50px;
    }
    li{
      display: flex;
      height: 16px;
      margin-right: 20px;
      font-size: 14px;
      img{
        width: auto;
        height: 18px;
      }
      svg{
        width: 30px;
      }
    }
  }
  .parteners{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 30px;
    margin: 50px 100px 0!important;
    &>div{
      margin: 0 auto;
      &:first-of-type{
        margin-left: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
      &:last-of-type{
        margin-right: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
    }
    @media (max-width: 767px) {
      margin: 50px 20px 0!important;
      grid-template-columns: repeat(1, auto);
    }
    .ant-col{
      margin-bottom: 20px;
    }
    img{
      height: 40px;
      display: block;
      margin: 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
  .parteners2{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    margin: 50px 100px 0!important;
    @media (max-width: 767px) {
      margin: 50px 0 0!important;
      grid-template-columns: repeat(2, auto);
    }
    &>div{
      margin: 0 auto;
      &:first-of-type{
        margin-left: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
      &:nth-of-type(4){
        margin-right: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
      &:nth-of-type(5){
        margin-right: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
      &:nth-of-type(6){
        margin-left: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
      &:last-of-type{
        margin-right: 0;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
      }
    }
    img{
      height: 40px;
      width: auto;
      max-width: calc(50vw - 30px);
      filter:grayscale(100%);
    }
  }
  .raiFinance{
    margin-top: 80px;
    .ant-col{
      &:nth-of-type(1){
        img{
          width: 40px;
        }
      }
      &:nth-of-type(2){
        img{
          width: 25px;
        }
      }
      &:nth-of-type(3){
        img{
          width: 25px;
        }
      }
      &:nth-of-type(4){
        img{
          width: 25px;
        }
      }
    }
    .item{
      border-radius: 12px;
      background: #fff;
      padding: 24px;
      .avator{
        background: #5542F6;
        border: 4px solid #626BE9;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        // img{
        //   width: 40px;
        //   height: auto;
        // }
      }
      h2{
        color: #111827;
        font-weight: 700;
        font-size: 16px;
        margin: 20px 0 10px;
      }
      p{
        font-weight: 400;
        font-size: 14px;
        color: #6B7280;
      }
    }
  }
  .teamCard{
    position: relative;
    padding: 40px 0;
    overflow: hidden;
    min-height: 300px;
    .ant-card-cover{
      span{
        margin: 0 auto; 
      }
    }
    h2{
      text-align: center;
      font-size: 24px;
      margin-top: 30px;
    }
    h3{
      font-size: 16px;
      text-align: center;
      margin-top: 0;
    }
    .des{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
      transform: translateY(45px);
      background-color: #3C17B8;
      color: #fff;
      padding: 30px;
      text-align: left;
      white-space: pre-wrap;
      .name, .title, p{
        text-align: left;
        color: #fff;
      }
    }
    .ant-card-body{
      padding: 0;
    }
    &:hover{
      .des{
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
      }
    }
  }
  .products{
    margin: 50px auto 20px;
    padding: 0;
    position: relative;
    overflow: hidden;
    .btns{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .ant-btn-primary{
        background: #3C17B8;
        border-radius: 15px;
        height: 40px;
        padding: 0 10px;
      }
    }
    .slick-list{
      border-radius: 12px;
      margin-top: 180px;
      border: 1px solid #D1D5DB;
      border-radius: 12px;
      background: #fff;
      @media (max-width: 992px) {
        margin-top: 300px;
      }
      @media (max-width: 767px) {
        padding-top: 0;
        margin-top: 0;
      }
    }
    .slick-arrow{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      &.slick-prev{
        left: 35px;
        @media (max-width: 767px) {
          left: 10px;
        }
      }
      &.slick-next{
        right: 35px;
        @media (max-width: 767px) {
          right: 10px;
        }
      }
    }
    .slick-dots{
      list-style: none;
      position: absolute;
      top: -180px;
      width: 100%;
      padding: 0;
      display: grid!important;
      grid-template-columns: repeat(4, 1fr);
      background: #E5E7EB;
      padding: 12px;
      grid-gap: 12px;
      border-radius: 12px;
      @media (max-width: 992px) {
        top: -300px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 767px) {
        background: none;
      }
      li{
        // box-shadow: 5px 5px 10px #E5E5E5;
        border-radius: 12px;
        padding: 16px;
        cursor: pointer;
        line-height: 1.2;
        background: #E5E7EB;
        a{
          display: flex;
          flex-direction: column;
          font-size: 14px;
          h2{
            color: #111827;
            font-weight: 700;
            font-size: 16px;
          }
          p{
            color: #6B7280;
            font-size: 14px;
            font-weight: 400;
          }
        }
        &.slick-active{
          background: #fff;
        }
        img{
          width: 100px;
          @media (max-width: 767px) {
            width: 50px;
          }
        }
      }
      @media (max-width: 767px) {
        display: none!important;
        justify-content: center;
        margin: 20px 0px 0px;
        a{
          display: none;
          h2,p{
            display: none;
          }
        }
        li{
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: #C4C4C4;
          display: inline-block;
          padding: 0;
          &.slick-active{
            background: #25AAE1;
          }
        }
      }
    }
    .item{
      width: 100%;
      display: flex!important;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &>div{
        height: 730px!important;
        width: 100%!important;
        @media (max-width: 767px) {
          height: 250px!important;
        }
      }
      p{
        color: #000;
        text-align: left;
        font-size: 12px;
        padding: 0;
        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 15px;
          text-align: left;
        }
      }
      .ant-btn{
        margin-top: 40px;
        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
      video{
        margin: 0 auto;
        display: block;
        height: 414px;
        width: auto;
        border-radius: 12px;
        overflow: hidden;
        @media (max-width: 992px) {
          border-radius: 16px;
          height: 260px;
          width: auto;
        }
        @media (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .defiCarousel{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
    .item{
      img{
        height: 216px;
        border-radius: 12px;
      }
      .ant-btn-link{
        display: flex;
        align-items: center;
        text-align: left;
        padding: 10px 0;
        margin-bottom: 24px;
        cursor: auto;
      }
    }

  }
  .tokenInfo{
    // background: #F3F4F6;
    border-radius: 12px;
    // padding: 24px 0px;
    // margin-top: 20px;
    margin-bottom: 24px;
    .tokenName {
      img{
        height: 25px;
        width: auto;
        cursor: pointer;
      }
    }
    p{
      font-size: 14px;
    }
  }
  h1{
      font-size: 60px;
      font-weight: 700;
      @media (max-width: 1200px) {
          font-size: 40px;
      }
  }
  h2{
      font-size: 40px;
      font-weight: 700;
      @media (max-width: 767px) {
          font-size: 32px;
      }
  }
  h3{
      font-size: 14px;
      margin-top: 35px;
      color: #190232;
  }
  p{
      font-size: 16px;
      line-height: 24px;
      color: #79869F;
  }
  img{
      // width: 100%;
  }
  .footer{
    background: #0E0D0F;
    .content{
      margin: 0 auto;
      max-width: 1200px;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1200px) {
        padding: 100px 50px;
      }
    }
    .footer_logo{
      margin-right: 30px;
      margin-bottom: 5px;
      height: 40px;
      width: auto;
    }
  }
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}


main {
  // height: 100vh;
  // overflow: scroll;
  scroll-snap-type: y mandatory;
}

main > section {
  height: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: min(var(--slide-width), 100%);
  margin-inline: auto;
  padding-block: 0rem;
  // overflow: hidden;
}


:root {
  --c-fg: black;
  --c-bg: snow;
  --c-primary: royalblue;
  --c-neutral: lavender;
  --c-notice: orange;
  --c-lines: var(--c-primary);
  --c-shadows: var(--c-neutral);
  --slide-width: 65ch;
  --slide-motion: 500ms ease;

  font-family: sans-serif;
  background-color: var(--c-bg);
  color: var(--c-fg);
}

::selection {
  background-color: var(--c-primary);
  color: var(--c-bg);
}


.any_box {
  background: url(../../assets/images/home_new/bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 0 24px;
  }
  .inner_box {
    position: relative;
    z-index: 2;
    padding-bottom: 200px;
  }
  .any_content {
    margin: 0 auto 20px;
    max-width: 650px;
    color: #DDD1E1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .item_box {
    text-align: left;
    max-width: 943px;
    margin: 0 auto;
    cursor: pointer;
  }
  .b_img {
    width: 680px;
    position: absolute;
    right: -200px;
    bottom: -30px;
    z-index: 0;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
.dive_box {
  padding: 50px 0 50px;
  text-align: center;
  background: linear-gradient(138deg, #1B0D26 0%, rgba(0, 0, 0, 0.74) 25.52%, rgba(0, 0, 0, 0.65) 71.88%, rgba(6, 64, 33, 0.30) 100%);
  @media (max-width: 767px) {
    padding: 50px 24px 10px;
  }
}
.finance_box {
  max-width: 1160px;
  margin: 150px auto;
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 10px 24px;
    margin: 20px auto;
  }
}
.finance_box_left {
  max-width: 400px;
  margin-right: 50px;
  > p {
    margin-top: 10px;
    margin-bottom: 60px;
    color: #DDD1E1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media (max-width: 767px) {
    > p {
      margin-bottom: 30px;
    }
  }
}
.finance_ex {
  color: #D9D9D9;
  font-family: Righteous;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.finance_box_right {
  flex: auto;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
}
.bg_box {
  width: 1200px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  @media (max-width: 767px) {
    width: 100%;
    left: 0;
  }
}
.ball_bg {
  width: 100%;
}
.ball1 {
  width: 190px;
  position: absolute;
  bottom: 500px;
  right: 300px;
  animation: fdball 2s ease-in-out infinite alternate;
}
.ball2 {
  width: 260px;
  position: absolute;
  bottom: 220px;
  right: 700px;
  animation: fdball2 2s ease-in-out infinite alternate;
}
.ball3 {
  width: 400px;
  position: absolute;
  bottom: 70px;
  right: 290px;
  animation: fdball3 3s ease-in-out infinite alternate;
}

@keyframes fdball {
  from { transform: translate(0,0);}
  to {transform: translate(0,16px)}
}
@keyframes fdball2 {
  from { transform: translate(0,0);}
  to {transform: translate(-12px,-18px)}
}
@keyframes fdball3 {
  from { transform: translate(0,0);}
  to {transform: translate(20px,22px)}
}

@media (max-width: 767px) {
  .ball1, .ball2, .ball3 {
    animation: none;
    display: none;
  }
}

@primary-color: #5542F6;